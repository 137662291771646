import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { useAccount } from 'wagmi'
import { useLocalStorage } from 'react-use'

const ProtectedRoute = ({ children }) => {
  const [accessToken] = useLocalStorage('token')
  const { isConnected } = useAccount()
  if (!accessToken || !isConnected) {
    return <Navigate to="/login-wallet" />
  }
  return children
}

ProtectedRoute.propTypes = {
  children: PropTypes.object,
}

export default ProtectedRoute
