import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { arbitrum, arbitrumSepolia, polygonZkEvm, polygonZkEvmTestnet } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ProtectedRoute from './components/ProtectedRoute'
import { SWRConfig } from 'swr'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const LoginWallet = React.lazy(() => import('./views/pages/login/LoginWallet'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [arbitrum, arbitrumSepolia, polygonZkEvm, polygonZkEvmTestnet],
  [publicProvider()],
)

const config = createConfig({
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
    new MetaMaskConnector({ chains }),
  ],
  publicClient,
  webSocketPublicClient,
})

class App extends Component {
  render() {
    return (
      <SWRConfig
        value={{
          refreshInterval: 5000,
        }}
      >
        <WagmiConfig config={config}>
          <HashRouter basename={process.env.REACT_APP_ENV === 'staging' ? '/dex-admin' : ''}>
            <Suspense fallback={loading}>
              <Routes>
                <Route exact path="/login" name="Login Page" element={<Login />} />
                <Route exact path="/login-wallet" name="Login Page" element={<LoginWallet />} />
                <Route exact path="/register" name="Register Page" element={<Register />} />
                <Route exact path="/404" name="Page 404" element={<Page404 />} />
                <Route exact path="/500" name="Page 500" element={<Page500 />} />
                <Route
                  path="*"
                  name="Home"
                  element={
                    <ProtectedRoute>
                      <DefaultLayout />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </Suspense>
          </HashRouter>
          <ToastContainer />
        </WagmiConfig>
      </SWRConfig>
    )
  }
}

export default App
